import { makeStyles } from '@material-ui/core/styles';
import { contentActions } from 'client_shared/src/state/actions/contentActions';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ROUTES_ANP } from 'shared';
import ArticleContent from '../components/contents/article/ArticleContent';
import MicroSiteArticleHeader from '../components/contents/microSite/MicroSiteArticleHeader';
import ContentScreen from '../components/pages/ContentScreen';

const useStyles = makeStyles(theme => ({
    bodyLayout: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '32px',
        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '48px',
            marginBottom: '48px'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
            marginBottom: '64px',
        },
    },
}));


function ContentArticle() {
    const classes = useStyles();
    const [breadCrumbTrailTail, setBreadCrumbTrailTail] = useState([]);
    const article = useSelector(state => state.content.content);

    useEffect(() => {
        if (!article) {
            return;
        }

        const breadCrumbTrailTail = [{
            title: article.title,
            url: ROUTES_ANP.CONTENT_ARTICLE(article.id)
        }];

        setBreadCrumbTrailTail(breadCrumbTrailTail);
    }, [article]);


    return (
        <ContentScreen showTitleBar={false} breadCrumbsTrailTail={breadCrumbTrailTail}
                       includePageInBreadCrumbTail={true}>
            {article &&
            <>            
                {article.floatingImage && 
                    <div style={{
                        position: 'fixed',
                        bottom: '0px',
                        right: '0px', 
                        zIndex: 10000,
                        lineHeight: '0px',
                        margin: '0px',
                        padding: '0px',
                    }}>
                        <img 
                            src={`/content/images/${article.floatingImage}`}
                            alt="Pályázat logo"
                            style={{width: 'auto', height: `${article.floatingImageHeight}px`}}
                        />
                    </div>
                }
                <MicroSiteArticleHeader article={article}/>
                <div className={clsx('padding-article', classes.bodyLayout)}>
                    <ArticleContent content={article.body}/>
                </div>
            </>
            }
        </ContentScreen>
    );
}

ContentArticle.initState = (environment) => {
    const { dispatch, params } = environment;

    const promises = [];

    const contentId = params.id;

    if (contentId) {
        promises.push(dispatch(contentActions.loadContent(contentId)));
    }

    return promises;
};

export default ContentArticle;